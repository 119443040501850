/* eslint-disable */

import * as SchemaTypes from '@freelancerepublik/graphql-types';

import { gql } from '@freelancerepublik/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type UserNotifiedSubscriptionVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type UserNotifiedSubscription = { __typename?: 'Subscription', userNotified: { __typename?: 'UserNotification', id: string, status: SchemaTypes.NotificationStatusEnum, message: string } };


export const UserNotifiedDocument = gql`
    subscription userNotified {
  userNotified {
    id
    status
    message
  }
}
    `;

/**
 * __useUserNotifiedSubscription__
 *
 * To use a Smart Subscription within a Vue component, call `useUserNotifiedSubscription` as the value for a `$subscribe` key
 * in the component's `apollo` config, passing any options required for the subscription.
 *
 * @param options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.subscribe
 *
 * @example
 * {
 *   apollo: {
 *     $subscribe: {
 *       userNotified: useUserNotifiedSubscription({
 *         variables: {},
 *         loadingKey: 'loading',
 *         fetchPolicy: 'no-cache',
 *       }),
 *     },
 *   }
 * }
 */
export const useUserNotifiedSubscription = createSmartSubscriptionOptionsFunction<
  UserNotifiedSubscription,
  UserNotifiedSubscriptionVariables,
  ApolloError
>(UserNotifiedDocument);
