"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTalentAdvocateReachable = exports.HIGHEST_DEGREE_LEVEL_OPTIONS = exports.HIGHEST_DEGREE_LEVEL_MAPPING = exports.TIMING_OPTIONS = exports.ACTIVITY_OPTIONS = exports.FREELANCE_STATUS_OPTIONS = exports.EXPECTED_CONTRACT_DISPLAY_OPTIONS = exports.EXPECTED_CONTRACT_OPTIONS = exports.SOURCE_OPTIONS = exports.FreelanceTraitList = void 0;
const graphql_types_1 = require("@freelancerepublik/graphql-types");
const User_core_1 = require("./User.core");
exports.FreelanceTraitList = [...User_core_1.UserTraitList, 'source', 'status', 'jobCategories', 'expectedContract'];
exports.SOURCE_OPTIONS = [
    { value: graphql_types_1.FreelanceImportSourceEnum.Apec, label: 'APEC' },
    { value: graphql_types_1.FreelanceImportSourceEnum.ClientReferral, label: 'Recommandation client' },
    { value: graphql_types_1.FreelanceImportSourceEnum.FreelanceReferral, label: 'Recommandation freelance' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Ib, label: 'IB' },
    { value: graphql_types_1.FreelanceImportSourceEnum.JeanMichel, label: 'Jean-Michel' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Freework, label: 'Freework' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Linkedin, label: 'Linkedin' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Malt, label: 'Malt' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Organic, label: 'Organique' },
    { value: graphql_types_1.FreelanceImportSourceEnum.Other, label: 'Autre' },
    { value: graphql_types_1.FreelanceImportSourceEnum.PoleEmploi, label: 'Pôle Emploi' },
];
exports.EXPECTED_CONTRACT_OPTIONS = [
    { value: graphql_types_1.ExpectedContract.Cdi, label: 'CDI' },
    { value: graphql_types_1.ExpectedContract.Freelance, label: 'Freelance' },
    { value: graphql_types_1.ExpectedContract.FreelanceOrCdi, label: 'Freelance ou CDI' },
    { value: graphql_types_1.ExpectedContract.Nc, label: 'Non communiqué' },
];
exports.EXPECTED_CONTRACT_DISPLAY_OPTIONS = [
    { value: graphql_types_1.ExpectedContract.Cdi, label: 'CDI' },
    { value: graphql_types_1.ExpectedContract.Freelance, label: 'Free' },
    { value: graphql_types_1.ExpectedContract.FreelanceOrCdi, label: 'F/C' },
    { value: graphql_types_1.ExpectedContract.Nc, label: 'NC' },
];
exports.FREELANCE_STATUS_OPTIONS = [
    { value: graphql_types_1.FreelanceStatus.Prospect, label: 'Prospect', color: 'black' },
    { value: graphql_types_1.FreelanceStatus.Pending, label: 'Profil incomplet', color: 'red' },
    { value: graphql_types_1.FreelanceStatus.Stashed, label: 'Qualif non prioritaire', color: 'stone' },
    { value: graphql_types_1.FreelanceStatus.Intest, label: 'Qualif prioritaire', color: 'blue' },
    { value: graphql_types_1.FreelanceStatus.Candidate, label: 'À qualifier', color: 'orange' },
    { value: graphql_types_1.FreelanceStatus.Ko, label: 'Refusé', color: 'red' },
    { value: graphql_types_1.FreelanceStatus.Ok, label: 'Membre FRK', color: 'green' },
    { value: graphql_types_1.FreelanceStatus.Runner, label: 'Runner', color: 'green' },
    { value: graphql_types_1.FreelanceStatus.Former, label: 'Former', color: 'green' },
];
exports.ACTIVITY_OPTIONS = [
    { value: graphql_types_1.Activity.Freelance, label: 'Freelance' },
    { value: graphql_types_1.Activity.Salary, label: 'Salarié' },
    { value: graphql_types_1.Activity.Student, label: 'Étudiant' },
];
exports.TIMING_OPTIONS = [
    { value: graphql_types_1.Timing.Full, label: 'Temps plein' },
    { value: graphql_types_1.Timing.Partial, label: 'Temps partiel' },
    { value: graphql_types_1.Timing.Partial_3, label: 'Temps partiel 3/5' },
    { value: graphql_types_1.Timing.Partial_4, label: 'Temps partiel 4/5' },
    { value: graphql_types_1.Timing.Both, label: 'Temps plein ou partiel' },
];
exports.HIGHEST_DEGREE_LEVEL_MAPPING = {
    INCONNU: -1,
    PRE_BAC: 0,
    BAC: 1,
    BAC_PRO: 2,
    BAC_3: 3,
    BAC_5: 4,
    BAC_8: 5,
};
// ! These options are meant to be used only for Algolia - see solution and associated tradeoffs
// ! Values are casted by Algolia to string -> map option value transform from number to string here
// ? It has been estimated too expensive to implement custom mapping at the refinement list level
// ! Attention, we use numeric values in DB but string values in Algolia...
exports.HIGHEST_DEGREE_LEVEL_OPTIONS = [
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.INCONNU, label: 'Inconnu', default: true },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.PRE_BAC, label: 'Pre-bac' },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.BAC, label: 'Bac' },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.BAC_PRO, label: 'Bac pro' },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.BAC_3, label: 'Bac+3' },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.BAC_5, label: 'Bac+5' },
    { value: exports.HIGHEST_DEGREE_LEVEL_MAPPING.BAC_8, label: 'Bac+8' },
].map(option => ({ ...option, value: option.value.toString() }));
function isTalentAdvocateReachable(status) {
    return [graphql_types_1.FreelanceStatus.Ok, graphql_types_1.FreelanceStatus.Former, graphql_types_1.FreelanceStatus.Runner, graphql_types_1.FreelanceStatus.Intest].includes(status);
}
exports.isTalentAdvocateReachable = isTalentAdvocateReachable;
