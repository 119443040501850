import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, gql, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

import pkg from '@freelancerepublik/version/package.json';
import { getInstance } from '../../ui/plugins/auth';

// ? Re-export for convenience in ui-* workspaces
export { getMainDefinition, gql };

const SCHEMA_VERSION = pkg.version; // Must be a string.
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

async function getAccessToken() {
  const $auth = getInstance();

  if ($auth.isAuthenticated) {
    const accessToken = await $auth.getTokenSilently();

    return accessToken;
  }
}

const contextLink = setContext(async () => {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
  };

  const accessToken = await getAccessToken();

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return { headers };
});

const createGraphQLErrorLink = ({ sentry }) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    sentry.withScope(scope => {
      scope.setTag('graphql_operation', operation.operationName);
      scope.setExtra('variables', operation.variables ? JSON.stringify(operation.variables, null, 2) : undefined);

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          // Don't log TokenExpired exceptions to Sentry
          if (extensions && extensions.errorCode === 'TokenExpired') {
            return;
          }

          scope.setExtra('error_type', 'graphql');
          scope.setExtra('location', JSON.stringify(locations, null, 2));
          scope.setExtra('path', path);

          sentry.captureMessage(message, sentry.Severity.Error);
        });
      }

      // Do not report network errors in development
      // as they are triggered at every rebuild
      // of a shared dependency between API Server & a UI :
      // - Dependency update with new compiled code
      // - API Server & UIs compile & restart
      // -> Most of the time Vue app starts before API Server which is not started yet
      // -> Network errors... bouh failed to fetch server is not reachable...
      if (networkError && process.env.VUE_APP_CONFIG_ENV !== 'development') {
        const err = `[${operation.operationName}, network error]: ${networkError.message}`;

        scope.setExtra('error_type', 'network');
        scope.setExtra('networkError', JSON.stringify(networkError, null, 2));
        sentry.captureMessage(err, sentry.Severity.Error);
      }
    });
  });

const createApolloTerminatingLink = ({ apiUri, NODE_ENV }) => {
  // Explicitly instantiate SubscriptionClient for more clarity (https://www.apollographql.com/docs/link/links/ws/#options)
  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.VUE_APP_SOCKET_URL,
      connectionParams: async () => {
        const accessToken = await getAccessToken();

        return { accessToken };
      },
    })
  );

  const httpLink = new HttpLink({
    uri: apiUri,
    credentials: NODE_ENV === 'production' ? 'same-origin' : 'include',
  });

  return split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );
};

export async function createApolloClient({ apiUri, NODE_ENV, sentry }) {
  const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
      User: {
        fields: {
          freelance: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(global.window.localStorage),
  });

  // Read the current schema version from AsyncStorage.
  const currentVersion = global.window.localStorage.getItem(SCHEMA_VERSION_KEY);

  if (currentVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    await persistor.restore();
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    // and mark ourselves as having updated to the latest version.
    await persistor.purge();
    global.window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      cleanTypenameLink,
      contextLink,
      createGraphQLErrorLink({ sentry }),
      // Especially useful in development, see network error handling in the error link
      new RetryLink(),
      createApolloTerminatingLink({ apiUri, NODE_ENV }),
    ]),
    shouldBatch: true,
    queryDeduplication: true,
    connectToDevTools: NODE_ENV !== 'production',
  });

  client.onClearStore(async () => {
    await persistor.purge();
  });

  return client;
}
