import Vue from 'vue';
import Vuex from 'vuex';

import forms from '@freelancerepublik/commons/ui/store/forms';
import { initVuexStore } from '@freelancerepublik/commons/ui/store/initVuexStore';
import messages from '@freelancerepublik/commons/ui/store/messages';

import help from './help';
import selection from './selection';

// Vue.use(Vuex) must be called before creating a store instance
Vue.use(Vuex);

const vuexModules = {
  forms,
  help,
  messages,
  selection,
};
const vuexPersistModules = ['selection', 'help'];
const vuexStore = initVuexStore(Vuex, vuexModules, vuexPersistModules);

export default vuexStore;
