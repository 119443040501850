/* eslint-disable */

import * as SchemaTypes from '@freelancerepublik/graphql-types';

import { gql } from '@freelancerepublik/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type GetAlgoliaSettingsViaOverEngineeredArchitectureQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetAlgoliaSettingsViaOverEngineeredArchitectureQuery = { __typename?: 'Query', settings?: { __typename?: 'Settings', algoliaSearchId: string, algoliaSearchKey: string, testId?: string | null } | null };


export const GetAlgoliaSettingsViaOverEngineeredArchitectureDocument = gql`
    query getAlgoliaSettingsViaOverEngineeredArchitecture {
  settings {
    algoliaSearchId
    algoliaSearchKey
    testId
  }
}
    `;

/**
 * __useGetAlgoliaSettingsViaOverEngineeredArchitectureQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetAlgoliaSettingsViaOverEngineeredArchitectureQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getAlgoliaSettingsViaOverEngineeredArchitecture: useGetAlgoliaSettingsViaOverEngineeredArchitectureQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetAlgoliaSettingsViaOverEngineeredArchitectureQuery = createSmartQueryOptionsFunction<
  GetAlgoliaSettingsViaOverEngineeredArchitectureQuery,
  GetAlgoliaSettingsViaOverEngineeredArchitectureQueryVariables,
  ApolloError
>(GetAlgoliaSettingsViaOverEngineeredArchitectureDocument);
