/* eslint-disable no-param-reassign */

export default {
  namespaced: true,
  state: {
    missionId: null,
    matching: false,
    boolean: false,
    freelances: [],
    freelancesNotSelected: [],
    consultedFreelances: {},
  },
  getters: {
    hasConsultedFreelance:
      state =>
      ({ freelanceId, missionId }) =>
        (state.consultedFreelances[missionId] || []).includes(freelanceId),
  },
  actions: {
    setMission({ commit }, { missionId, matching, boolean }) {
      commit('setMission', { missionId, matching, boolean });
    },
    addFreelance({ commit }, { freelance, tracking }) {
      commit('addFreelance', { freelance, tracking });
      commit('removeJustificationToNonSelection', freelance);
    },
    addJustificationToNonSelection({ commit }, { freelance, tracking }) {
      commit('addJustificationToNonSelection', { freelance, tracking });
    },
    setConsultedFreelance({ commit }, { freelanceId, missionId }) {
      commit('setConsultedFreelance', { freelanceId, missionId });
    },
    removeFreelance({ commit }, freelanceId) {
      commit('removeFreelance', freelanceId);
    },
    removeJustificationToNonSelection({ commit }, freelance) {
      commit('removeJustificationToNonSelection', freelance);
    },
    reset({ commit }) {
      commit('reset');
    },
  },
  mutations: {
    setMission(state, { missionId, matching, boolean }) {
      state.missionId = missionId;
      state.matching = matching;
      state.boolean = boolean;
    },
    addFreelance(state, { freelance, tracking }) {
      state.freelances.push({ ...freelance, ...tracking });
    },
    addJustificationToNonSelection(state, { freelance, tracking }) {
      state.freelancesNotSelected.push({ ...freelance, ...tracking });
    },
    removeFreelance(state, freelanceId) {
      state.freelances = state.freelances.filter(freelance => freelance.mongoObjectId !== freelanceId);
    },
    removeJustificationToNonSelection(state, freelance) {
      state.freelancesNotSelected = state.freelancesNotSelected.filter(
        freelanceNotSelected => freelanceNotSelected.mongoObjectId !== freelance.mongoObjectId
      );
    },
    reset(state) {
      state.missionId = null;
      state.freelances = [];
      state.matching = false;
      state.boolean = false;
      state.freelancesNotSelected = [];
    },
    setConsultedFreelance(state, { freelanceId, missionId }) {
      if (!state.consultedFreelances[missionId]) {
        state.consultedFreelances[missionId] = [];
      }

      if (!state.consultedFreelances[missionId].includes(freelanceId)) {
        state.consultedFreelances = {
          ...state.consultedFreelances,
          [missionId]: [...state.consultedFreelances[missionId], freelanceId],
        };
      }
    },
  },
};
