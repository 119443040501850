/* eslint-disable no-param-reassign */
import { merge } from '@freelancerepublik/helpers';

/**
 * @typedef {Object} GenericFlashMessageAlertType
 * @property {string} [id]
 * @property {string} message
 * @property {number} [time] - Display timeout (s). Set to `null` for a persistent notification.
 * @property {'info'|'invalid'|'valid'|'warning'} [colorClass] - Display timeout (s)
 */

export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  actions: {
    /**
     * @param commit
     * @param dispatch
     * @param state
     * @param {GenericFlashMessageAlertType} alert
     */
    pushFlashMessage({ commit, dispatch }, alert) {
      const _alert = merge(
        {
          // Between [3s, 20s] depending on the message length
          time: alert.time || Math.min(20, Math.max(3, Math.floor(alert.message.length / 10))),
          colorClass: 'valid',
        },
        alert
      );

      // Suffix the alert ID with a random value to ensure a unique internal ID (e.g. helpful for UserNotifications)
      _alert.id = [alert.id, Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)]
        .filter(s => s !== null && s !== undefined)
        .join('-');
      _alert.message = _alert.message.replace(/GraphQL error: /g, '');

      if (_alert.message.includes('Network error')) {
        _alert.message = 'Connexion avec le serveur impossible';
      }

      // try-catch FIX testing Error: "Failed to execute 'setItem' on 'Storage': access is denied for this document"
      try {
        commit('pushFlashMessage', _alert);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      // Hide only non-persistent messages
      if (_alert.time !== null) {
        setTimeout(() => {
          try {
            // FIX Error: Failed to execute 'setItem' on 'Storage': access is denied for this document
            dispatch('popFlashMessage', _alert.id);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }, _alert.time * 1000);
      }

      return _alert.id;
    },
    popFlashMessage({ commit }, id) {
      commit('popFlashMessage', id);
    },
  },
  mutations: {
    /**
     * Add or replace a flash message depending on the ID.
     *
     * @param {Object} state
     * @param {GenericFlashMessageAlertType} alert
     */
    pushFlashMessage(state, alert) {
      // Remove alert messages starting with the same ID prefix
      const [idPrefixToReplace] = alert.id.split('-');
      const alerts = state.alerts.filter(a => a.id.split('-')[0] !== idPrefixToReplace);

      alerts.push(alert);
      state.alerts = alerts;
    },
    popFlashMessage(state, id) {
      state.alerts = state.alerts.filter(element => element.id !== id);
    },
  },
};
