<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import pkg from '@freelancerepublik/version/package.json';

export default {
  name: 'FrkVersionUpgrader',
  data() {
    return {
      intervalId: undefined,
      remoteVersion: undefined,
    };
  },
  computed: {
    ...mapGetters('forms', ['isAnyEditedForm']),
    shouldUpgrade() {
      return this.remoteVersion !== undefined && pkg.version !== this.remoteVersion && !this.isAnyEditedForm;
    },
  },
  watch: {
    shouldUpgrade() {
      if (this.shouldUpgrade) window.location.reload();
    },
  },
  mounted() {
    if (['production', 'staging'].includes(process.env.VUE_APP_CONFIG_ENV)) {
      this.startAppUpgrader();
    }
  },
  beforeDestroy() {
    // In production, this hook is never called when leaving the page/tab
    // This function is here just in case this component is moved from App to another component to
    // prevent from memory leaks.
    this.stopAppUpgrader();
  },
  methods: {
    async getRemoteVersion() {
      try {
        // Change the query string for each request to bypass the browser cache
        const versionURI = `${window.location.protocol}//${window.location.host}/version?ts=${new Date().valueOf()}`;
        const response = await axios.get(versionURI);

        this.remoteVersion = response.data;
      } catch (err) {
        this.$sentry.captureException(err);
      }
    },
    startAppUpgrader() {
      // Every minute, check if a new version has been deployed
      this.intervalId = window.setInterval(() => this.getRemoteVersion(), 60 * 1000);
    },
    stopAppUpgrader() {
      if (this.intervalId) {
        window.clearInterval(this.intervalId);
      }
    },
  },
  render() {
    return null;
  },
};
</script>
